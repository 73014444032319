import axios, { AxiosResponse } from 'axios';
import { ContactType } from './contactType';
import replaceBackendUrl from '@/app/utils/replaceBackendUrl';
import { ContactPreferencesType } from './contactPreferencesType';

class CrmService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = `${process.env.BACKEND_URL}/backend/crm`;
    }

    async getContactByEmail(email: string): Promise<AxiosResponse<ContactType[]>> {
        let url = `${this.baseUrl}/contact?email=${email}`;

        try {
            url = process.env.NODE_ENV === 'development' ? replaceBackendUrl(url) : url;
            const response = await axios.get(url, {
              headers: {
                authorization: `Bearer ${process.env.BACKEND_API_KEY}`,
                'Content-Type': 'application/json',
              },
            });
            return response; 
          } catch (error) {
            if (axios.isAxiosError(error)) {
              throw new Error(`Error fetching contact: ${error.response?.status} - ${error.message}`);
            } else {
              throw new Error(`Unexpected error: ${error}`);
            }
          }
    }

    async createContact(firstName: string, lastName: string, email: string) {
      let url = `${this.baseUrl}/contact`;
      url = process.env.NODE_ENV === 'development' ? replaceBackendUrl(url) : url;
  
      try {
          const response = await axios.post(
              url,
              { firstName,
                lastName, 
                email }, 
              {
                headers: {
                  Authorization: `Bearer ${process.env.BACKEND_API_KEY}`, // Ensure Authorization is correct
                  'Content-Type': 'application/json',
                },
              }
          );
          return response.data;
      } catch (error) {
          if (axios.isAxiosError(error)) {
              throw new Error(`Error saving contact: ${error.response?.status} - ${error.message}`);
          } else {
              throw new Error(`Unexpected error: ${error}`);
          }
      }
    }

    async getContactPreferences(email: string): Promise<AxiosResponse<ContactPreferencesType>> {
      let url = `${this.baseUrl}/contact/preferences`;
      try {
            url = process.env.NODE_ENV === 'development' ? replaceBackendUrl(url) : url;
            const response = await axios.post(
              url,
              {email:email}, 
              {
                headers: {
                  Authorization: `Bearer ${process.env.BACKEND_API_KEY}`, // Ensure Authorization is correct
                  'Content-Type': 'application/json',
                },
              }
            );
            return response;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            throw new Error(`Error fetching contact/preferences: ${error.response?.status} - ${error.message}`);
          } else {
            throw new Error(`Unexpected error: ${error}`);
          }
        }
    }

    async saveContactPreferences(preference: ContactPreferencesType) {
      let url = `${this.baseUrl}/contact/preferences`;
      try {
          url = process.env.NODE_ENV === 'development' ? replaceBackendUrl(url) : url;
          const response = await axios.put(
              url,
              preference, 
              {
                headers: {
                  Authorization: `Bearer ${process.env.BACKEND_API_KEY}`, // Ensure Authorization is correct
                  'Content-Type': 'application/json',
                },
              }
          );
          return response.data;
      } catch (error) {
          if (axios.isAxiosError(error)) {
              throw new Error(`Error saving contact: ${error.response?.status} - ${error.message}`);
          } else {
              throw new Error(`Unexpected error: ${error}`);
          }
      }
    }

    async saveContactOptIn(email:string, optin:string) {
      let url = `${this.baseUrl}/contact/optin`;
      try {
          url = process.env.NODE_ENV === 'development' ? replaceBackendUrl(url) : url;
          const response = await axios.put(
              url,
              {
                email,
                optin
              }, 
              {
                headers: {
                  Authorization: `Bearer ${process.env.BACKEND_API_KEY}`,
                  'Content-Type': 'application/json',
                },
              }
          );
          return response.data;
      } catch (error) {
          if (axios.isAxiosError(error)) {
              throw new Error(`Error to Save Contact OptIn: ${error.response?.status} - ${error.message}`);
          } else {
              throw new Error(`Unexpected error in Save Contact OptIn: ${error}`);
          }
      }
    }

    async getContactPartialEmail(email: string): Promise<AxiosResponse<string>> {
      let url = `${this.baseUrl}/contact/partial-email`;

      try {
        url = process.env.NODE_ENV === 'development' ? replaceBackendUrl(url) : url;
        const response = await axios.post(
          url,
          {email:email}, 
          {
            headers: {
              Authorization: `Bearer ${process.env.BACKEND_API_KEY}`, // Ensure Authorization is correct
              'Content-Type': 'application/json',
            },
          }
        );
        return response;
      } 
      catch (error) {
        if (axios.isAxiosError(error)) {
          throw new Error(`Error fetching contact/partial-email: ${error.response?.status} - ${error.message}`);
        } else {
          throw new Error(`Unexpected error: ${error}`);
        }
      }
    }


}

export default CrmService;
